import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import axios from "axios";
import Helmet from 'react-helmet';

const Thanks = ({ location }) => {
	
	useEffect(() => {
		let email = JSON.parse(localStorage.getItem('sessionId'))
		// console.log('session id yay',email)
		sendEmail(email)
	}, [])

	let sendEmail = async(email) => {
		try{
			const response = await axios.get(`https://barcelonacodeschool.com/payment/checkout-session?sessionId=${email}`)
			// console.log('yay', response)
		}catch(error){
			// console.log('E R R O R BOOM', error.message)
		}
	}



	   // sendEmail()

	   let redirect = () => navigate('/')

	   useEffect(() => {
	   	setTimeout(function() { redirect() }, 7000);
	   	let cost = localStorage.getItem('bubblegum') || 580
	   	window.dataLayer = window.dataLayer || [];
	   	function gtag(){dataLayer.push(arguments);}
	   	// gtag('js', new Date());
	   	// gtag('config', 'AW-856512071');
	   	gtag('event', 'conversion', {
	   		'send_to': 'AW-856512071/Y6DLCLmAxsoBEMestZgD',
	   		'value': Number(cost),
	   		'currency': 'EUR',
	   		'transaction_id': ''
	   	})
	   }, []);

	   return <div className="white">
	   <Helmet 
	   
	   	/>
	   	<h1>All done now!</h1>
	   	<h2>Our team will get in touch with you promptly regarding the next steps.</h2>
	   	</div>
	   }





	   export default Thanks
